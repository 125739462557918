@import "variables";

.wrapper {
    background: $wrapper-background;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;

    @include prefix(
        (
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1),
        )
    );

    @include media-breakpoint-down(md) {
        max-width: 100%;
    }

    > .main-wrapper {
        background: #fff;
        padding: 60px;
        padding-right: 340px;

        @include media-breakpoint-down(md) {
            padding: 30px;
        }

        .section-title {
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 500;
            color: darken($theme-color, 15%);
            position: relative;
            margin-top: 0;
            margin-bottom: 20px;

            @media print {
                .fa-circle {
                    display: none;
                }
            }
        }

        .section {
            margin-bottom: 60px;

            @media print {
                margin-bottom: 10px;
            }
        }

        .experiences-section {
            .item {
                margin-bottom: 30px;
                page-break-inside: avoid;

                .meta {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;

                    .logo {
                        width: 70px;

                        a:after {
                            display: none;
                        }

                        img {
                            width: 56px;
                            height: 56px;
                        }
                    }

                    .upper-row {
                        width: calc(100% - 70px);

                        .company {
                            color: $text-grey;
                        }
                    }
                }
            }
        }

        .educations-section {
            .item {
                margin-bottom: 30px;
            }
        }

        .upper-row {
            position: relative;
            overflow: hidden;
            margin-bottom: 2px;

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }
        }

        .job-title,
        .degree {
            color: $text-color;
            font-size: 16px;
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 500;
        }

        .time {
            position: absolute;
            right: 0;
            top: 0;
            color: $text-grey;

            @include media-breakpoint-down(md) {
                position: static;
                display: block;
            }

            @media print {
                float: right;

                @include prefix(
                    (
                        print-color-adjust: exact,
                    )
                );
            }
        }

        .university {
            margin-bottom: 10px;
        }

        .project-title {
            font-size: 16px;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 5px;
            color: darken($theme-color, 15%);
        }

        .projects-section {
            @media print {
                display: none;
            }

            .intro {
                margin-bottom: 30px;
            }

            .projects {
                justify-content: center;
                align-items: center;

                .item {
                    margin-bottom: 15px;
                    text-align: center;
                }
            }
        }

        .publication-title {
            font-size: 16px;
            font-weight: 400;
            color: $text-color;
            margin-bottom: 1px;
        }

        .publication-authors {
            font-style: italic;
            color: $text-grey;
            margin-bottom: 1px;
        }

        .publications-section {
            .intro {
                margin-bottom: 30px;
            }

            .item {
                margin-bottom: 15px;
            }
        }
    }
}
