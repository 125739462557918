/* ======= Base ======= */

body {
    font-family: "Roboto", sans-serif;
    color: $text-color-secondary;
    background: $smoky-white;
    font-size: 14px;
    padding: 30px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    @media print {
        font-size: 12pt;
    }

    a {
        color: darken($theme-color, 15%);

        &:hover {
            text-decoration: underline;
            color: inherit;
        }

        &:focus {
            text-decoration: none;
        }
    }

    p {
        line-height: 1.5;
    }
}
