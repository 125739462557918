@charset "utf-8";

// Bootstrap v4.5.2
@import "bootstrap/scss/bootstrap";

// FontAwesome v5.1.1
@import "fontawesome";

// Portfólio
@import "default";
