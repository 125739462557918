@media print {
    @page {
        size: A4;
    }

    *, *:before, *:after {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    img {
        max-width: 100% !important;
        @include prefix(
            (
                filter: grayscale(1),
            )
        );
    }

    a {
        text-decoration: none !important;

        &[href]:after {
            content: " (" attr(href) ")";
        }
    }
}