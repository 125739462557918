/// Mixin to prefix a property
/// @param {String} $property - Property name
/// @param {*} $value - Property value
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: (webkit moz o)) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
  }
}