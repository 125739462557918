@import "variables";

.sidebar-wrapper {
    background: $sidebar-background;
    position: absolute;
    right: 0;
    width: 280px;
    height: 100%;
    min-height: 800px;
    color: #fff;

    @include media-breakpoint-down(md) {
        position: static;
        width: inherit;
    }

    @media print {
        position: static;
        float: right;
        width: 40%;
        border-left: solid 1px #dadada;
        border-bottom: solid 1px #dadada;
        margin-left: 5mm;
        
        @include prefix(
            (
                print-color-adjust: exact,
            )
        );
    }

    a {
        color: #fff;
    }

    .profile-container {
        padding: 30px;
        background: rgba(0, 0, 0, 0.2);
        text-align: center;
        color: #fff;
    }

    .name {
        font-size: 32px;
        font-weight: 900;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .tagline {
        color: rgba(256, 256, 256, 0.6);
        font-size: 16px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 0;
    }

    .profile {
        margin-bottom: 15px;
    }

    .avatar {
        max-width: 100px;
        margin-bottom: 15px;
        border: 0px solid #fff;

        @include prefix(
            (
                border-radius: 100%,
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1),
            )
        );

        @media print {
            @include prefix(
                (
                    filter: grayscale(1),
                )
            );
        }
    }

    .contact-list {
        .fas,
        .fab {
            margin-right: 5px;
            font-size: 18px;
            vertical-align: middle;
        }

        li {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .container-block {
        padding: 30px;
    }

    .container-block-title {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .education-container {
        .item {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            .degree {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 5px;
            }

            .meta,
            .time {
                color: rgba(256, 256, 256, 0.6);
                font-weight: 500;
                margin-bottom: 0px;
                margin-top: 0;
            }

            .meta {
                font-size: 14px;
            }
        }
    }

    .languages-container {
        .lang-desc {
            color: rgba(256, 256, 256, 0.6);
        }
    }

    .languages-list {
        margin-bottom: 0;

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .interests-list {
        margin-bottom: 0;

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
