@import "variables";
@import "animations";

.footer {
    padding-top: $footer-gutter;

    > .copyright {
        display: block;
        line-height: 1.6;
        text-align: center;
        color: $footer-copyright-color;
        font-size: $footer-copyright-fontsize;

        > .fa-heart {
            color: $footer-heart-color;
            margin: 0 3px;

            @if $footer-heart-pulse-animation {
                animation: pulse 1s infinite;
            }
        }
    }

    @if $footer-hidden-on-print {
        @media print {
            display: none;
        }
    }
}
